.decision-type-document {
    background-color: #fff;
    cursor: pointer;
    border-radius: 6px;
    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0px 0 1px rgb(10 10 10 / 2%);
    color: #4a4a4a;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 1.25rem !important;
    white-space: nowrap;
    overflow: hidden;
    font-size: 1rem;

    .decision-type-document-title{
        text-overflow: ellipsis;
        overflow: hidden;

    }

    .folder-icon {
        font-size: 1.5rem;
        font-variation-settings: "FILL" 1, "wght" 300, "GRAD" 0, "opsz" 48;
        color: #4a4a4a !important;
    }

}

.documents-controls-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

@media screen and (max-width: 900px) {
    .documents-download-btn {
        display: none !important;
    }
}
