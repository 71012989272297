@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");


:root {
  /* Colors */
  --color-primary: #bdb6b0;
  --color-primary-light: #e8f6fb;
  --color-secondary: #908f8f;
  --color-secondary-light: #def8e8;
  --color-success: #48c78e;
  --color-success-light: #effaf5;
  --color-white: #fff;
  --color-warning: #ffe08a;
  --color-danger: red;
  --color-grey: #f4f4f4;
  --border-radius: 8px;

  --mobile-breakpoint: 900px;

}

$border-radius: 8px;

/* Resets */
* {
  font-family: 'Manrope', 'Roboto';
  margin: 0px;
}



html, body, #root {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
  font-size: 13px;
  color: #4a4a4a;
}


.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

h1 {
  font-size: 52px;
}

h2 {
  font-size: 38px;
}

h3 {
  margin-bottom: 12px;
}

img {
    height: auto;
    max-width: 100%
}

.text-center {
  text-align: center;
}



p {
  font-size: 16px;
  line-height: 26px;
}

h1 {
  margin-bottom: 40px;
}

h2 {
  margin-bottom: 16px;
}

body {
  /* Put some constrast for white boxes */
  background-color: #f6f6f6
}

.form-control {
  display: flex;
  flex-direction: column;
}

.MuiButton-root:hover {
  background-color: #5a5a5a !important;
}

input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  .refused_color {
    background-color: #ed8b8b;
}

.approved_color {
    background-color: var(--color-success);
}

.changes_requested_color {
    background-color: grey;
}