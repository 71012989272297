.projects-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.projects-banner-container {
    height: 80%;
}

.projects-info-container {
    height: 20%;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 900px) { 
    .projects-banner-container {
        height: 32vh;
    }
    
    .projects-info-container {
        height: 9vh;
    }
}