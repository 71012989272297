.news-preview-container {
    padding: 0px !important;



    .news-p-media-container {
        width: 100%;
        aspect-ratio: 1.4 / 1;

        .news-p-img {
            width: 100% ;
            height: 100%;
            object-fit: cover;
        }
    }

    .news-p-content-container {
        padding: 12px;

        .news-p-date {
            font-size: 12px;
            font-style: italic;
        }

        .news-content {
            font-size: 14px;
            p {
                font-size: 14px;
            }
            
       
            
        }
    }
}

