.conversation-container {
    .conversation-info-container {
        height: 6%;

        .conversation-details-title {
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 16px;
        }
    }
    .conversation-messages-container {
        height: 70%;
        overflow-y: auto;
        margin-bottom: 8px;
        padding: 12px;
    }

    .conversation-controls-container {
        margin-top: 8px;
        display: flex;
        flex-direction: column;
        justify-content: end;

        .conversation-input {
        }
    }
}

.conversation-participant-container {
    margin-bottom: 14px;
    
    .conversation-participant-entity {
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 0px;
        
    }
}

.conversation-participant {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-top: 12px;
}