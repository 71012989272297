
  .manual-wrapper {
    display: flex;
    border-radius: 4px;
    align-items: center;
    padding: 14px 0px;
    
  }

  .manual-wrapper:nth-child(even) {
    background-color: #E3E3E3;
  }

  .manual-column {
    width: 20%;
    margin: 0px;
    margin-left: 2%;
  
  }
