.login-btn {
    padding: 16px 10px !important;
    border-radius: 10px !important

}

.login-container {
    height: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;


    .login-logo-img {
        margin: 0 auto;
        margin-top: 8vh;
        max-height: 200px
    }

    .login-title {
        text-align: center;
        margin: 0px;
    }

    .login-submessage {
        color: #939292;
        text-align: center;
        margin-top: 8px;
        margin-bottom: 6vh;
    }

    .login-form {
        padding: 0px 1.5rem 1.5rem
    }
    .login-error-message {
        color: red;
    }


    .input {
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-bottom: 16px;
    }

    .input__field {
        background-color: #d4d4d4;
        border-radius: 22px;
        animation: none;

        .MuiInputBase-root {
            border-radius: 22px;
        }

        .MuiOutlinedInput-notchedOutline {
            border: none;
            animation: none;

        }
    }

    .input__label {
        text-transform: uppercase;
        letter-spacing: 0.8px;
        margin-left: 4px;
        margin-bottom: 2px;
        color: #949494;
    }


    .auth-link {
        font-size: 15px;
        display: flex;
        margin-top: 6vh;
        justify-content: center;
        color: var(--color-secondary);
        text-decoration: none;
    }

}


@media screen and (max-width: 900px) {
    .login-container {
        display: flex;
        justify-content: center;
    }


    .login-banner-container {
        display: none;
    }
  }


