.c-user-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
}

.user-selected {
    font-weight: bold;
}