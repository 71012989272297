.decision-add-btn-wrapper {
    margin: 24px 0px;
    display: flex;
    flex-direction: row;

    .rights-btn {
        margin-left: auto;
    }
}

.decision-header {
    border-bottom: 2px solid grey;
    padding-bottom: 8px;
    font-weight: bold;
}

.decision-property {
    color: #7a7a7a;
}

.decision-title {
    font-weight: 600;
}

.decision-grid-item {
    padding: 5px 0px;
    cursor: pointer;
    align-items: center;
}

.decision-grid-item:hover {
    background-color: #fafafa;
}


.decisions-progress-counter {
    font-weight: 700;
    display: flex;
    width: 100%;
    height: 24px;
    border-radius: 99999px;
    white-space: nowrap;
    overflow: hidden;
}

.decided-counter {
    font-weight: 700;
    background-color: var(--color-success);
    display: flex;
    height: 100%;
    color: white;
    align-items: center;
    justify-content: center;
}

.changes-counter {
    font-weight: 700;
    background-color: var(--color-warning);
    display: flex;
    height: 100%;
    color: white;
    align-items: center;
    justify-content: center;
}

.refused-counter {
    font-weight: 700;
    background-color: var(--color-danger);
    display: flex;
    height: 100%;
    color: white;
    align-items: center;
    justify-content: center;
}

.notdecided-counter {
    font-weight: 700;
    background-color: #f5f5f5;
    display: flex;
    height: 100%;
    color: rgba(0,0,0,.7);
    align-items: center;
    justify-content: center;
}

.mobile-decision-container {
    width: 100%;
    padding: 12px 0px !important;
    cursor: pointer;
}

.mobile-decision-property {
    display: flex;
    margin: 0px 12px;
    justify-content: space-between;
    padding: 12px;
}

.mobile-d-property-title {
    font-weight: bold;
}

.decision-action-container{
    display: flex;
    gap: 4px;
}