.upload-label {
    display: flex;
    padding: 60px 0px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

#fileUpload {
    display: none;
}

.file-upload-main-container {
    width: 100%;
    border: 1px solid grey;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
}

.file-upload-file-container {
    display: flex;
    align-items: center;
    padding: 8px;
    max-width: 520px;
    margin-bottom: 16px;
    background-color: white;
    color: black;
    border: 1px solid grey;
    border-radius: 4px;
    width: 100%;

    .file-upload-icon-container {
        width: 5%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        
        .file-upload-file-icon {
            color: #6e90e8;
            font-size: 32px;
        }
    }

    .file-upload-content-container {
        width: 90%;
        margin-left: auto;

        .file-upload-content-wrapper {
            display: flex;
            align-items: center;

            .file-upload-percentage {
                margin-left: auto;
            }
        }
    }

    .file-upload-progress {
        width: 100%;
    }
}


.file-upload-tagger {
    margin-left: auto;
}