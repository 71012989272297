.project-switcher-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    padding: 0px 24px;
}

.switcher-txt-btn {
    text-decoration: underline;
    font-size: 14px;
    letter-spacing: 0.2px;
    cursor: pointer;
}

.active-project {
    font-size: 18px;
}