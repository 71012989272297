.decision-preview-container {
    padding: 2px;
    border-radius: var(--border-radius);
    cursor: pointer;
    margin-bottom: 6px;

    .d-p-title {
        display: flex;
        align-items: center;
    }
}

.chip-deadline {
    background-color: #f5d681 !important;
}


.decision-preview-container:hover {
    background-color: var(--color-grey);
}

.decision-preview-status-chip {

}

.d-p-status-container{
    display: flex;
    justify-content: flex-end;
}