.dd-title {
    margin-bottom: 2px;
}

.decision-details-property-container {
    display: flex;
    gap: 12px;
    align-items: center;
    font-weight: 600;
}


.decision-details-proposals-container {
    margin-top: 24px;

    .dd-proposals-container {
        margin-top: 32px;
    }
}

.dd-status-bar {
    color: white;
    padding: 12px 24px;
    display: flex;
    align-items: center;
    gap: 8px;
}



.dd-proposal-wrapper {
    padding: 0px !important;
}

.dd-proposal-content-wrapper {
    padding: 24px;
}

.dd-proposal-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 12px;

    .dd-proposal-title {
        font-size: 1.25rem;
        letter-spacing: 0.4px;
        font-weight: bold;
    }


}

.dd-actions-container {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 8px;
}

.dd-proposal-content {
    margin-top: 12px;
}

.dd-proposal-approved {
    display: flex;
    align-items: center;
    color: var(--color-success);
    font-weight: bold;
}

.dd-details-empty-proposals {
    height: 48vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .dd-details-empty-description {
        font-size: 38px;
        font-style: italic;
        text-align: center;
        line-height: 48px;
    }
}

.dd-add-documents {
    display: flex;
    cursor: pointer;
    margin-top: 8px;
}


@media screen and (max-width: 900px) {
    .dd-proposal-header {


    }

    .dd-actions-container {
        flex-direction: column;
        .dd-action-btn {
            width: 100%;
            margin-bottom: 2px;
        }      
    }
}