.file-preview-container {
    position: relative;
    display: flex;
    cursor: pointer;
    padding: 6px 0px !important;
    justify-content: space-between;

    .pdf-icon {
        width: 24px;
        height: 24px;
    }

    .signed-doc-icon {
        position: absolute;
        font-variation-settings: "FILL" 1;
        display: block;
        top: -10px;
        left: -10px;
    }

    .file-preview-header-container {
        display: flex;
        padding: 8px;
        align-items: center;
        justify-content: center;
        width: 10%;

        .preview-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .file-preview-info-container {
        display: flex;
        align-items: center;
        width: 70%;

        .file-preview-name {
            display: flex;
            gap: 4px;
            overflow: hidden;
        }
    }

    .file-preview-download-container {
        display: flex;
        align-items: center;
        width: 10%;

        .file-preview-download-content-container {
            display: none;
            cursor: pointer;
        }
    }
}

.file-tags-wrapper {
    display: flex;
    padding: 0px 12px;
    padding-bottom: 12px;
    margin-top: -6px;

    .file-signed {
        background-color: #48c78e;
        border-radius: 9999px;
        color: #fff;
        display: inline-flex;
        font-size: .75rem;
        height: 2em;
        justify-content: center;
        line-height: 1.5;
        padding-left: 0.75em;
        padding-right: 0.75em;
        white-space: nowrap;
        align-items: center;
    }
}



.file-preview-container:hover .file-preview-download-content-container {
    display: block;
}

@media screen and (max-width: 900px) {
    .file-preview-download-content-container {
        display: block !important;
    }
}



.file-preview-viewer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .file-previewer-controls-bar {
        padding: 8px;
    }

    .pdf-previewer {
        width: 85%;
        height: 90%;
    }
}