.decision-status {
    display: flex;
    align-items: center;
    font-weight: bold;
    gap: 4px;
}

.ds-approved {
    color: var(--color-success);
}

.decisionstatus-icon {
    font-size: 20px;
}

.ds-refused {
    color: red;
}