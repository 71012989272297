@import '../../styles/index.scss';

.sidebar-content {
    height: calc(100% - 60px);

    .logo-container {
        height: 20%;
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 140px;
            height: 140px;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    .navigation-container {
        margin-top: 16px;
        height: 72%;
        display: flex;
        flex-direction: column;
    }
 
    .nav-pill {
        display: block;
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        font-size: 1.25rem !important;
        color: #7a7a7a;
    }

    .nav-pill-icon {
        padding-left: 30px;
    }

    .nav-bottom {
        margin-top: auto;
    }

    .nav-pill-active {
        background-color: #bdb6b0;
        color: #fff !important;
        font-weight: 600;
    }

    .nav-pill-text {
        margin-left: 8px;
    }

    .nav-pill:hover {
        background-color: #dadce0;
        cursor: pointer;
    }
}