.message-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 16px 0px;

    .message-wrapper-sender {
        flex-direction: row-reverse;
    }

    .message-wrapper {
        display: flex;
        width: 100%;

        .message-person-container {
            height: 50px;
            width: 50px;
            display: flex;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            text-align: center;
            object-fit: cover;
            font-size: 20px;
            background-color: #b5b5b5;
            color: white;
        }
    
        .message-content-container {
            width: 70%;
            margin: 0px 12px;

            .message-content {
                background-color: var(--color-grey);
                padding: 8px 16px;
                border-radius: var(--border-radius);

                .message-sender {
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
            }
        }


    }

    .message-timestamp {
        font-size: 12px;
        color: #7a7a7a;
    }

    .message-text {
        font-size: 15px;
    }

}

.read-info-container {
    display: flex;
    gap: 4px;
}

.message-unread {
    color: #9d9d9d;
}