.decision-card-container {
    cursor: pointer;
    
    .decision-card-content-container {
        display: flex;
        align-items: center;
        padding: 12px !important;
        margin: 0px 0px !important;

        .decision-card-status-container {
            height: 68px;
            width: 68px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
    
            .decision-card-status-icon {
                font-size: 38px;
            }

            .approved-icon {
                color: var(--color-success);
            }

            .undecided-icon {
                color: black;
            }
        }

        .decision-card-title {
            margin-left: 14px;
            font-size: 1.3rem;
            display: flex;
            flex-direction: column;
        }
    }

   
}