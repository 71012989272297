.data-field {
    background-color: rgba(245, 245, 245, 0.72);
    border-radius: 4px;
    position: relative;
    padding: 0.75rem;

    .data-field-label {
        font-size: 0.8rem;
        color: #7a7a7a;
    }

    .data-field-value {
        color: #4a4a4a;
    }
}