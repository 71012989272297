.conversation-item {
    cursor: pointer;
    margin: 0px !important;
    padding: 16px 12px !important;
    margin-bottom: 8px !important;
    display: flex;

    .conv-status-container {
        display: flex;
        align-items: center;
        margin-right: 12px;
        width: 12px;

        .conv-unread {
            width: 12px;
            height: 12px;
            background-color: #FFA500;
            border-radius: 50%;
        }
    }

    .c-ind-wrapper {
        margin-right: 16px;
    }

    .c-content-wrapper {
        width: 60%;
    }

    .conversation-title {
        font-weight: bold;
        font-size: 1.25rem;
        color: #363636;
    }
    
    .conversation-unit {
        display: flex;
        align-items: center;
        font-size: 1rem;
    }


}

.conversation-item:hover {
    background-color: var(--color-grey);
}
